import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/css/app.css'
import './assets/fontawesome/css/all.css'

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')


document.addEventListener('swUpdated', event => {
    const registration = event.detail;

    if (confirm('New version available. Do you want to update?')) {
        if (registration.waiting) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            registration.waiting.addEventListener('statechange', e => {
                if (e.target.state === 'activated') {
                    window.location.reload();
                }
            });
        }
    }
});