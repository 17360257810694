<template>
  <PageTitle />
  <SelectedVehiclePanel :isChoose="true" :noSelectedMsg="'Choose vehicle below'" />
<!--  <RentalVehiclePanel />-->
  <SearchPanel v-if="!isShowRentalVehicle"/>
</template>

<script>

import PageTitle from "@/components/Common/PageTitle.vue";
import SelectedVehiclePanel from "@/components/Vehicle/Selected.vue";
import SearchPanel from "@/components/Vehicle/Search.vue";
/*import RentalVehiclePanel from "@/components/Vehicle/RentalVehicle.vue";*/

export default {
  name: 'SelectVehicleView',
  components: {
    /*RentalVehiclePanel,*/
    SearchPanel,
    SelectedVehiclePanel,
    PageTitle,
    //
  },
  data() {
    return {
      isShowRentalVehicle: false,
    }
  },
  mounted() {
    // Set selected vehicle
    // this.$store.dispatch('vehicle/selected')

    // Set a details based on selected vehicle
    // this.$store.dispatch('vehicle/details')

    // Set page title
    this.$store.dispatch('setPageTittle', 'Select Vehicle', '/dashboard')
  }
}
</script>