import axios from 'axios';
import { API_URL } from '../../config/constants';
export default {
    namespaced: true,
    state: {
        selectedVehicle: {},
        vehicles: [],
        hasSelected: false,
        noSelectedMsg: 'Please click the "Choose Vehicle" button below.'
    },
    getters: {
        data: state => state.vehicles,
    },
    actions: {
        get({state, commit }) {
            let userData = JSON.parse(localStorage.getItem('userData'))
            let data = {
                id: userData.id,
                tenantId: localStorage.getItem('tenantId')
            }
            console.log('TEST: ' + JSON.stringify(data))
            const config = {
                headers: {
                    'Authorization': `Bearer `+ localStorage.getItem('apiToken'),
                    'Content-Type': 'multipart/form-data'
                }
            };

            axios.post(API_URL + 'vehicle/get', data, config)
                .then(function (response) {
                    commit('setVehicles', response.data)
                    commit('renewSelectedVehicle')
                    console.log(response);
            }).catch((error) => {
                console.log(error)
                state.errorMsg = error.response.data.message
                state.isError = true
            });
        },
        selected({ commit }, vehicle) {
            commit('setSelectedVehicle', vehicle)
        },
        details({state}) {
            console.log(state.selectedVehicle)
        }
    },
    mutations: {
        setVehicles(state, data) {
            state.vehicles = data.vehicles
            localStorage.setItem('vehicleList', JSON.stringify(data.vehicles))
        },
        setSelectedVehicle(state) {
            let vehicle = JSON.parse(localStorage.getItem('selectedVehicle'));

            if (vehicle !== null) {
                // console.log(JSON.stringify(vehicle));
                // {"id":104,"branch_id":10,"vehicle_type_id":1,"unit_number":"118","vin_number":"1C6RR6FT2KS722828","license_plate_number":"TRE610","state_registered":null,"registration_number":null,"vehicle_group_id":null,"make":"Dodge","model":"DODGE RAM","year":"2019","gross_vehicle_weight_rating":null,"odometer":null,"rental":false,"leasing_agency":null,"created_at":"2024-04-05T08:54:15.000000Z","updated_at":"2024-04-05T08:54:15.000000Z","deleted_at":null,"status":"Safe to Drive","image":""}
                state.selectedVehicle = {
                    branch_id: vehicle.branch_id,
                    created_at: vehicle.created_at,
                    gross_vehicle_weight_rating: vehicle.gross_vehicle_weight_rating,
                    id: vehicle.id,
                    leasing_agency: vehicle.leasing_agency,
                    license_plate_number: vehicle.license_plate_number,
                    make: vehicle.make,
                    model: vehicle.model,
                    odometer: vehicle.odometer,
                    registration_number: vehicle.registration_number,
                    rental: vehicle.rental,
                    state_registered: vehicle.state_registered,
                    status: vehicle.status ? 'Safe to Drive' : 'Not Safe to Drive',
                    unit_number: vehicle.unit_number,
                    updated_at: vehicle.updated_at,
                    vehicle_group_id: vehicle.vehicle_group_id,
                    vehicle_type_id: vehicle.vehicle_type_id,
                    vin_number: vehicle.vin_number,
                    year: vehicle.year,
                    image: (vehicle.image === '' ? 'img/no-image.png' : vehicle.image),// "https://imagedelivery.net/sMKxZiRKOecJDS323VwSkA/35187b1b-fe78-4f6c-7ca2-c39c72e9b400/thumb"
                }
                state.hasSelected = true
            }
        },
        renewSelectedVehicle(state) {
            state.vehicles.forEach(function(vehicle) {
                //  unit number, vehicle make/model/year/color, the state/license plate, and vin number.
                if (vehicle.id === state.selectedVehicle.id) {
                    localStorage.setItem('selectedVehicle', JSON.stringify(vehicle))
                    // commit('setSelectedVehicle', vehicle)
                }
            });
        }
    }
}